body{
  background: url('/assets/img/bg/pattern_1.png') repeat;
}

.loader{
  position: fixed;
  width: 100%;
  z-index: 9999;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffa825;

  & .ac{
    position: absolute;
    top: calc(50% - 60px);
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .spinner {
    margin: 50px auto;
    width: 30px;
    height: 30px;
    position: relative;
  }

  .cube1, .cube2 {
    background-color: #FFF;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
  }

  .cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  @-webkit-keyframes sk-cubemove {
    25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
    50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
    75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
    100% { -webkit-transform: rotate(-360deg) }
  }

  @keyframes sk-cubemove {
    25% {
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
      } 50.1% {
          transform: translateX(42px) translateY(42px) rotate(-180deg);
          -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
        } 75% {
            transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
            -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
          } 100% {
              transform: rotate(-360deg);
              -webkit-transform: rotate(-360deg);
            }
  }


}

.cookies{
  position: fixed;
  z-index: 990;
  background: #222;
  bottom: 0;
  right: 0;
  max-width: 500px;
  padding: 20px 50px;
  color: #FFF;
  font-size: 13px;
  box-shadow:  0 0 1px #999;

  & a{
    color: #ff931c !important;
    cursor: pointer;
  }

  & ul{
    margin: 15px 0 0 0;
    padding: 0;

    & li{
      display: inline-block;

      & i{
        margin-right: 4px;
      }

      & .btn{
        border-radius: 0;
      }

    }

  }

}

#sticky-wrapper{
  height: auto !important;
}

.header {
  width: 100%;
  height: 100%;
  background: url(/assets/img/bg/br2.jpg) no-repeat center;
  background-size: cover;
  background-attachment: scroll;
  text-align: center;
  border-bottom: 2px solid #473f37;
  position: absolute;

  & .logo{
    margin-top: 15%;
    display: inline-block;
    width: 100%;

    @include media-breakpoint-down(md){
      margin-top: 50%;
    }

    & img{
      max-width: 410px;
      width: 100%;
      margin-bottom: 50px;

      @include media-breakpoint-down(md){
        width: 70%;
      }

    }

    & h1{
      width: 60%;
      max-width: 900px;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      display: block;
      color: #FFF;
      font-size: 34px;
      font-weight: 600;
      text-shadow: 0 0 2px #888;

      @include media-breakpoint-down(md){
        font-size: 22px;
        width: 100%;
        padding: 0 30px;
      }

    }

  }

  & .navigation{
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100% !important;
    z-index: 1020 !important;
    transition: all .2s ease-in-out;

    & .lsmall{
      margin: 21px 10px;
      display: none;

      & img{
        height: 40px;
      }
    }

    & .navbar-collapse{
      flex-direction: column;

      @include media-breakpoint-down(xs){
        background: #FFF;
      }

    }

    & .navbar-nav{
      display: inline-block;
      margin: 0;
      padding: 0;

      & .nav-item.hover{
        position: relative;

        &:after{
          margin-left: -20px;
          color: #FFF;
          font-size: 15px;

          @include media-breakpoint-down(xs){
            display: none;
          }

        }
      }

      & li{
        display: inline-block;

        @include media-breakpoint-down(xs){
          width: 100%;
        }

        & a{
          padding: 15px 25px;
          display: inline-block;
          color: #FFF;
          font-weight: bold;
          font-size: 18px;

          &:hover, &:active, &:focus{
            color: #ffa825;
          }

          @include media-breakpoint-down(xs){
            color: #444;
            width: 100%;
          }
          
        }
      }

      & ul.dropdown-menu{
        border-radius: 0;

        @include media-breakpoint-down(xs){
          display: none !important;
        }


        & a{
          padding: 10px 25px;
          color: #666;
          font-size: 16px;
          font-weight: 500;

          &:hover, &:active, &:focus{
            color: #ffa825;
          }

          &:active, &:focus{
            background: #ffa825;
            color: #FFF;
          }
          
        }

      }

    }
  }

}

.navbar-toggler{
  border: none;
  width: 100%;
  text-align: right;
  outline: none !important;

  & span{
    color: #FFF;
    font-size: 24px;
    transition: color .2s ease-in-out;
    outline: none !important;
  }

}

.sticky-wrapper{
  &.is-sticky{

    & .navbar-toggler{
      border: none;
      width: auto;
      text-align: inherit;
      
      & span{
        color: #444;
      }

    }

    & .nav-item.hover{
      &:after{
        color: #444 !important;
      }
    }

    & .navigation{
      background: #FFF;
      border-bottom: 1px solid #CCC;
      text-align: left;

      @include media-breakpoint-down(xs){
        padding: 0 1rem;
      }

      & .lsmall{
        display: inline-block;

        @include media-breakpoint-down(xs){
          & img{
            height: 30px;
          }
        }

      }

      & .navbar-collapse{
        flex-direction: row;
        flex-flow: row-reverse;
      }

      & li{

        @include media-breakpoint-down(xs){
          width: 100%;
          text-align: center;
        }

        & a{
          color: #444;
          font-weight: 600;
          font-size: 16px;
          padding: 30px 25px;

          @include media-breakpoint-down(xs){
            padding: 20px 25px;
          }

        }

      }
    }
  }
}

#warum-wir{
  @include media-breakpoint-up(lg){
    & .mobile{
      display: none;
    }
    & .normal{
      display: block;
    }
  }

  @include media-breakpoint-down(md){
    & .normal{
      display: none;
    }

    & .mobile{
      display: block;

      & p {
        margin-bottom: 0;
        padding-left: 0;
      }

      & [class*="col-"]{
        padding: 0;

        &:first-child{
          margin-bottom: 15px;
        }
      }

    }
  }
}

.wrapper{
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  position: absolute;
  top:100%;
  left: 0;
  right: 0;

  & .content{

    & .content-box{
      background: #FFF;
      margin-bottom: 30px;

      & .content-box-cover{
        width: 100%;
        height: 280px;
        background: #CCC;
        background-size: cover !important;

        &.c1{
          background: url('/assets/img/bg/box1.jpg') center;
        }

        &.c2{
          background: url('/assets/img/bg/box2.jpg') center;
        }

        &.c3{
          background: url('/assets/img/bg/box3.jpg') center;
        }

        &.c4{
          background: url('/assets/img/bg/box4.jpg') center;
        }

        &.c5{
          background: url('/assets/img/bg/box5.jpg') center;
        }

        &.c6{
          background: url('/assets/img/bg/box6.jpg') center;
        }

      }

      & .content-box-title{
        text-align: center;

        & h2{
          padding: 35px 10px 15px 10px;
          font-weight: 600;
          font-size: 24px;
        }

        & p{
          font-size: 15px;
          padding: 0 15px 20px 15px;
          line-height: 1.3;
          color: #444;
        }

        & .btn{
          margin-bottom: 45px;
        }

      }

    }

    & .references{
      text-align: center;
      padding: 20px 0 0 0;
      width: 100%;

      @include media-breakpoint-down(md){
        padding: 0;
      }

      & .position-relative{
        height: 40px;
        margin: 40px 35px;

        @include media-breakpoint-down(md){
          margin: 40px 0;
          height: 120px;
        }

      }

      & p{
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 20px;
        line-height: 1.2;
        padding: 15px 0 0 0;
        font-size: 18px;
      }

      & ul{
        width: 100%;
        margin: 0;
        padding: 0;
        position: absolute;

        &:last-child{
          display: none;
        }

      }

      & li{
        display: inline-block;
        margin: 0 35px;
        transition: all .2s ease;

        @include media-breakpoint-down(md){
            margin: 20px 10px;
            width: calc(40% - 20px);
        }

        & img{
          transition: all .2s ease-in-out;
          max-height: 40px;
          margin: 0;
          filter: grayscale(0);

          @include media-breakpoint-down(md){
            max-height: 40px;
            max-width: 100%;
          }

          @include media-breakpoint-down(sm){
            max-height: 60px;
            width: 100%;
          }

          &:hover{
            filter: grayscale(1);
            opacity: 1;
          }

        }

      }

    }

    & .section{
      background: #FFF;
      padding: 100px 120px;

      @include media-breakpoint-down(xs){
        padding: 70px 30px;
      }

      &.tr{
        background: transparent;
        
        & h3{
          color: #444;
        }
      }

      & h3{
        text-align: center;
        font-weight: 600;
        font-size: 34px;
        position: relative;

        &:before{
          content: '';
          display: block;
          width: 120px;
          height: 2px;
          background: #ff931c;
          bottom: -20px;
          left: 0;
          right: 0;
          position: absolute;
          margin: 0 auto;
        }

        &:after {
          content: '';
          display: block;
          width: 90px;
          height: 2px;
          background: #ff931c;
          bottom: -25px;
          left: 0;
          right: 0;
          position: absolute;
          margin: 0 auto;
        }

      }

      & .text-leistungen{
        margin-top: 80px;
        color: #666;
        font-size: 20px;
        text-align: center;

        @include media-breakpoint-down(xs){
          font-size: 16px;
        }

      }

      & .contex{
        margin-top: 100px;

        & .plm-4{
          padding-left: 1.5rem;

          @include media-breakpoint-down(md){
            padding-left: 15px;
          }

        }

        & .prm-4{
          padding-right: 1.5rem;

          @include media-breakpoint-down(md){
            padding-right: 15px;
          }

        }

        @include media-breakpoint-down(md){
          & .pr{
            font-size: 15px;
          }
        }

        & .contact, address{
          & p{
            line-height: 1.2;
            margin: 0;
            padding: 0 0 6px 0;
          }
        }

        & address{
          & p:first-child{
            font-weight: 600;
            margin-bottom: 10px;
          }
        }

        & .contact-form{
          padding-right: 3rem;

          @include media-breakpoint-down(md){
            padding-right: 0;
          }

          & p{
            font-weight: 400;
            margin-bottom: 10px;
            line-height: 1.2;
            padding: 0 0 15px 0;
            font-size: 20px;
            color: #666;
          }

          & .form-control{
            background: #EEE;
            border: none;
            margin: 10px 0;
            border-radius: 0;
            padding: 8px 10px;
          }

        }

        & .contact-info{
          border-left: 3px solid #EEE;
          padding: 50px 0 50px 80px;

          @include media-breakpoint-down(md){
            padding: 50px 0 0 50px;
            border-left: none;
            border-top: 3px solid #EEE;
            margin-top: 50px;
          }

        }

      }

      & .about{
        background-color: #232323;
        background-image: url('/assets/img/bg/background-pattern-05.png');
        padding: 5em 0;
        margin: 0 -120px -100px -120px;

        @include media-breakpoint-down(xs){
          margin: 0 -30px -100px -30px;
        }

        & h3{
          color: #FFF;
        }

        & .features-list{
          text-align: center;
          margin-top: 50px;
          margin-left: 0;
          padding-left: 0;

          @include media-breakpoint-down(xs){
              margin-top: 70px;
          }

          & .sl{
            display: inline-block;
            margin: 50px 50px 0 50px;
            position: relative;

            @include media-breakpoint-down(xs){
              margin: 20px 0;
              width: 100%;
            }

            & .icon{
              position: absolute;
              top: 0;
              text-align: center;
              line-height: 140px;
              vertical-align: middle;
              left: 0;
              right: 0;
              font-size: 50px;
              color: #CCC;
              border-radius: 50%;
              width: 136px;
              margin: 2px auto;
              height: 136px;
              box-shadow: 0 0 5px #999;

              @include media-breakpoint-down(xs){
                  width: 100px;
                  height: 100px;
                  line-height: 96px;
                  font-size: 40px;
              }

              & img{
                width: 150px;
                height: auto;
                margin-left: 10px;
                margin-top: -15px;

                @include media-breakpoint-down(xs){
                  width: 100px;
                  margin-top: -10px;
                }

              }
            }

            &::before, &:before{
              clear: both;
              display: block;
              width: 140px;
              height: 140px;
              content: "";
              line-height: 140px;
              font-size: 48px;
              margin: 0 auto;
              color: #0078D7;
              border: 4px solid #ffffff;
              padding: 19px;
              border-radius: 50%;

              @include media-breakpoint-down(xs){
                width: 100px;
                height: 100px;
                line-height: 104px;
              }


            }

            & .ornament{
              position: relative;
              width: 1px;
              height: 40px;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 30px;
              background: #ffffff;

              @include media-breakpoint-down(xs){
                margin-bottom: 10px;
                height: 20px;
              }

              &:after{
                position: absolute;
                content: "";
                border: 1px solid #ffffff;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
                width: 11px;
                height: 11px;
                left: -5px;
                bottom: -11px;
              }

            }

            & .number{
              text-align: center;
              font-size: 60px;
              color: #FFF;
              text-shadow: 0 0 1px #CCC;
              font-weight: 600;

              @include media-breakpoint-down(xs){
                font-size: 40px;
              }

            }

            &>p{
              text-shadow: 0 0 1px #FFF;
              color: #FFF;
            }

            &>p.p1{
              margin-bottom: 0;
              padding-bottom: 0;
            }

          }

        }

      }

    }

  }

}

.modal{
  margin-top: 74px;
  margin-right: -17px;

  @include media-breakpoint-down(xs){
    margin-right: 0;
    margin-top: 10px;
  }

  & .modal-dialog{
    max-width: 1240px;
  }

  & .modal-content{
    border-radius: 0;
    border-color: #FFF;
    padding: 70px 120px;
    max-height: 800px;
    overflow: auto;

    @include media-breakpoint-down(xs){
      padding: 100px 20px 40px 20px;
    }

    & .close{
      position: fixed;
      right: 0;
      top:0;
      padding: 50px;
      z-index: 999;

      & span{
        background: #FFF;
      }
    }

    & h3{
      margin-bottom: 80px;
      text-align: center;
      font-weight: 600;
      font-size: 30px;
      position: relative;

     &:before {
        content: '';
        display: block;
        width: 120px;
        height: 2px;
        background: #ff931c;
        bottom: -20px;
        left: 0;
        right: 0;
        position: absolute;
        margin: 0 auto;
      }

      &:after {
        content: '';
        display: block;
        width: 90px;
        height: 2px;
        background: #ff931c;
        bottom: -25px;
        left: 0;
        right: 0;
        position: absolute;
        margin: 0 auto;
      }

    }

    & article.legal{
      & h4, & h5{
        font-size: 17px;
        font-weight: 600;
        color: #555;
        margin-bottom: 20px;
        margin-top: 30px;
      }

      & h5{
        font-size: 16px;
      }

      p{
        font-size: 14px;
      }

      & b{
        font-size: 13px;
        color: #444;
      }
    }

    @include media-breakpoint-down(xs){
      & p{
        font-size: 15px;
      }
    }

  }

}


.footer{
  width: 100%;
  background-color: #232323;
  background-image: url('/assets/img/bg/background-pattern-05.png');
  padding: 30px 30px;
  text-align: center;

  & .footer-content{
    & a{
      color: #FFF;
      font-size: 14px;
      font-weight: bold;
      padding: 0 10px;
      cursor: pointer;

      &:hover, &:active, &:focus{
        color: #ffa825;
      }
    }

    & p{
      color: #CCC;
      margin-top: 20px;
      font-size: 12px;
    }
  }
  & .power{
    margin: 20px 0 0 0;
    & a {
      color: #999;
      font-weight: bold;
      font-size: 12px;
      text-decoration: none !important;
    }
    & img{
      height: 26px;
      margin-left: 6px;
    }
  }
}

.btn.btn-warning{
  border-radius: 0;
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  background: #ff931c;
  border-color: #ff931c;
}

@include media-breakpoint-down(md){

  & .row.no-gutters-sm{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

}


.social-media{
  position: fixed;
  right: 0;
  top: 20%;
  z-index: 800;

  @include media-breakpoint-down(md){

    display: none !important;

  }

  & .facebook-icon{
    width: 52px;
    height: 52px;
    background: #3b5998;
    text-align: center;
    color: #FFF;
    line-height: 52px;
    font-size: 22px;
    border-radius: 4px 0 0 4px;
    float: right;
    clear: both;
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity .2s ease-in-out;

    @include media-breakpoint-down(md){

      display: none !important;

    }

  }

  & .sidebar{
    width: 300px;
    background: #FFF;
    display: inline-block;
    box-shadow: 0 0 5px #999;
    position: absolute;
    right: -310px;
  }
}

.facebook_link{
  font-weight: normal;
  padding: 10px;
  background: #555;
  width: 34px;
  height: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  margin-bottom: 18px;
  line-height: 30px;
  text-decoration: none !important;
  transition: all .2s ease-in-out;

  & i{
    vertical-align: middle;
  }

  &:hover, &:active, &:focus{
    background: #3b5998;
    color: #FFF !important;
  }

}